import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import FourZeroFour from './FourZeroFour';

const Routes = ({ className }) => (
  <div className={className}>
    <Switch>
      <Route
        exact
        path="/"
      >
        <Home />
      </Route>
      <Route>
        <FourZeroFour />
      </Route>
    </Switch>
  </div>
);

Routes.defaultProps = {
  className: ''
};

Routes.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default Routes;
