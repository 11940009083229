import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import Footer from './Footer';

const styles = {
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
};

const App = ({ classes }) => {
  useEffect(() => {
    fetch('/hello')
      .then(response => response.text())
      .then(text => console.log(text));

    fetch('/env')
      .then(response => response.text())
      .then(text => console.log(text));
  })

  return (
    <Router>
      <div className={classes.wrapper}>
        <Routes />
        <Footer />
      </div>
    </Router>
  )
};

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  App
);
