import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Button, withTheme } from '@material-ui/core';
import BookCoverImage from '../media/cover.jpg'
import AmazonImage from '../media/amazon-button.png';
import AppleImage from '../media/apple-button.png';

const styles = (theme) => ({
  wrapper: {
    backgroundSize: 'cover',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 50,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 25
    }
  },
  coverAndBuyButtonsWrapper: {
    width: 800,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column'
    }
  },
  coverImage: {
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: 250
    }
  },
  infoAndActions: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      alignItems: 'center',
      padding: 0
    }
  },
  button: {
    marginBottom: 12,
    width: 200
  },
  buyNowButton: {
    backgroundColor: 'rgba(55, 125, 34, 1)',
    color: 'White',
    fontSize: 20,
    height: 50,
    marginBottom: 15,
    '&:hover': {
      backgroundColor: 'rgba(55, 125, 34, 0.9)',
    }
  },
  questions: {
    width: 800,
    border: 'solid 2px black',
    padding: 25,
    borderRadius: 5,
    boxSizing: 'border-box',
    margin: '50px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '25px 0 0 0',
      borderRight: 0,
      borderLeft: 0,
      borderRadius: 0
    }
  },
  questionsHeader: {
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30
    }
  },
  question: {
    marginBottom: 25,
    fontSize: 26,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
      fontSize: 22
    }
  },
  description: {
    width: '100%',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center'
  },
  descriptionInnerWrapper: {
    fontSize: 26,
    width: 800,
    textAlign: 'center',
    padding: '50px 25px',
    color: 'White',
    [theme.breakpoints.down('sm')]: {
      fontSize: 22
    }
  },
  descriptionText: {
    '&:first-child': {
      marginTop: 0
    },
    '&:last-child': {
      marginBottom: 0
    }
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 34
    }
  },
  price: {
    fontSize: 26,
    marginBottom: 12,
  }
});

const Home = ({ classes }) => (
  <div className={classes.wrapper}>
    <div className={classes.coverAndBuyButtonsWrapper}>
      <img
        alt="Guitar Pedal Basics book cover"
        className={classes.coverImage}
        src={BookCoverImage}
      />
      <div className={classes.infoAndActions}>
        <div className={classes.title}>Guitar Pedal Basics</div>
        <div className={classes.price}>$6.99</div>
        {/* <Button
          className={classNames(classes.button, classes.buyNowButton)}
          variant="contained"
        >
          Buy Now (PDF)
        </Button> */}
        <a
          href="https://www.amazon.com/Guitar-Pedal-Basics-getting-frustration-ebook/dp/B08PTLKMW2"
          target="blank"
        >
          <img
            alt="Available at Amazon button"
            className={classes.button}
            src={AmazonImage}
          />
        </a>
        {/* <a
          href="https://books.apple.com/us/book/guitar-pedal-basics/id1544623962"
          target="blank"
        >
          <img
            alt="Available at Apple Books button"
            className={classes.button}
            src={AppleImage}
          />
        </a> */}
      </div>
    </div>
    <div className={classes.questions}>
      <h1 className={classes.questionsHeader}>Are you...</h1>
      <ul>
        <li className={classes.question}>getting into guitar pedals, but unsure about where to start?</li>
        <li className={classes.question}>bewildered by the number of effects on the pedal market, or uncertain about how to combine them for the best tone?</li>
        <li className={classes.question}>tired of wasting money on pedal after pedal only to remain confused and unsatisfied?</li>
      </ul>
    </div>
    <div className={classes.description}>
      <div className={classes.descriptionInnerWrapper}>
        <p className={classes.descriptionText}>If you answered “yes” to any of the above, or would just like to get into guitar pedals while saving time, frustration, and money, then Guitar Pedal Basics is for you!</p>
        <p className={classes.descriptionText}>With 9 chapters and 10 illustrations, Guitar Pedal Basics will guide you through the pedal landscape, explaining how different types of pedals work, and offering advice on how they can be used to get the best sounds out of your guitar rig.</p>
      </div>
    </div>
  </div>
);

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  Home
);
