import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';

const styles = {
  wrapper: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    color: 'white',
    padding: 15,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
  }
};

const LinkButton = ({ classes, className, children, linkPath }) => (
  <Link
    className={classNames(classes.wrapper, className)}
    to={linkPath}
  >
    {children}
  </Link>
);

LinkButton.defaultProps = {
  className: ''
};

LinkButton.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  linkPath: PropTypes.string.isRequired
};

export default
withStyles(styles)(
  LinkButton
);
