import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import KurtGif from '../media/kurt.gif';
import LinkButton from './LinkButton';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: `url(${KurtGif})`,
    backgroundSize: 'cover',
    padding: 50,
    height: 500,
    [theme.breakpoints.down('xs')]: {
      height: 'initial'
    }
  },
  header: {
    fontSize: 60,
    color: 'white',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 40
    }
  },
  subheader: {
    fontSize: 44,
    marginTop: 0,
    color: 'white',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 30
    }
  },
  image: {
    width: '100%'
  },
  button: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    color: 'black',
    fontSize: 24,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  }
});

const FourZeroFour = ({ classes }) => (
  <div className={classes.wrapper}>
    <h1 className={classes.header}>Bad Patch Cable??</h1>
    <p className={classes.subheader}>There's been a 404 on your pedalboard.</p>
    <LinkButton
      className={classes.button}
      linkPath="/"
    >
      click here to fix
    </LinkButton>
    {/* <img
      alt="Kurt Smashing Guitar"
      className={classes.image}
      src={KurtGif}
    /> */}
  </div>
);

FourZeroFour.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  FourZeroFour
);
