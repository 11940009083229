import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import InstagramLogo from '../media/instagram-logo.png';
import TwitterLogo from '../media/twitter-logo.png';
import FacebookLogo from '../media/facebook-logo.png';

const styles = (theme) => ({
  wrapper: {
    padding: 25
  },
  row: {
    margin: '25px 0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: '18px 0',
    }
  },
  text: {
    textAlign: 'center',
    margin: '0 0 25px 0'
  },
  socialMediaIcons: {
    display: 'flex'
  },
  socialMediaLink: {
    marginRight: 12
  },
  socialMediaIcon: {
    width: 40
  },
  email: {
    fontSize: 18,
    marginTop: -5,
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const Footer = ({ classes }) => (
  <footer className={classes.wrapper}>
    <div className={classes.row}>
      <p className={classes.text}>Get more gear tips and join the conversation at the Guitar Pedal Basics Instagram account.</p>
      <div className={classes.socialMediaIcons}>
        <a
          href="https://www.instagram.com/guitarpedalbasics"
          className={classes.socialMediaLink}
          target="blank"
        >
          <img
            alt="instagram logo"
            className={classes.socialMediaIcon}
            src={InstagramLogo}
          />
        </a>
        {/* <a
          href="https://twitter.com/pedalbasics"
          className={classes.socialMediaLink}
          target="blank"
        >
          <img
            alt="twitter logo"
            className={classes.socialMediaIcon}
            src={TwitterLogo}
          />
        </a>
        <a
          href="https://www.facebook.com/Guitar-Pedal-Basics-111612154026950"
          className={classes.socialMediaLink}
          target="blank"
        >
          <img
            alt="facebook logo"
            className={classes.socialMediaIcon}
            src={FacebookLogo}
          />
        </a> */}
      </div>
    </div>
    <div className={classes.row}>
      <p className={classes.text}>Questions, need help, or just want to talk about gear?</p>
      <a
        className={classes.email}
        href="mailto:benjaminokeefe@gmail.com"
        target="blank"
      >
        benjaminokeefe@gmail.com
      </a>
    </div>
    <div className={classes.row}>
      <span className={classes.copyright}>&copy; {new Date().getFullYear()} Guitar Pedal Basics</span>
    </div>
  </footer>
);

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default
withStyles(styles)(
  Footer
);
